<template>
  <a-config-provider :locale="locale">
    <router-view/>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
<style>
#app{
  width:100%;
  height:100%;
  margin:0px;
  padding:0px;
}
</style>