export default {
    install: (app, options) => {
        app.config.globalProperties.$formateDate =(day)=>{
            let Year = 0;
            let Month = 0;
            let Day = 0;
            let CurrentDate = "";
            Year = day.getFullYear();//ie火狐下都可以
            Month = day.getMonth()+1;
            Day = day.getDate();
            CurrentDate += Year + "-";
            if (Month >= 10 )
            {
                CurrentDate += Month + "-";
            }
            else
            {
                CurrentDate += "0" + Month + "-";
            }
            if (Day >= 10 )
            {
                CurrentDate += Day ;
            }
            else
            {
                CurrentDate += "0" + Day ;
            }
            return CurrentDate;
        };

        app.config.globalProperties.$formateDateAndWeek =(day)=>{
            let Year = 0;
            let Month = 0;
            let Day = 0;
            let CurrentDate = "";
            let weeks = ["星期日","星期一","星期二","星期三","星期四","星期五","星期六"];
            let date = 0;
            Year = day.getFullYear();//ie火狐下都可以
            Month = day.getMonth()+1;
            Day = day.getDate();
            date = day.getDay();
            CurrentDate += Year + "年";
            if (Month >= 10 )
            {
                CurrentDate += Month + "月";
            }
            else
            {
                CurrentDate += "0" + Month + "月";
            }
            if (Day >= 10 )
            {
                CurrentDate += Day +"日";
            }
            else
            {
                CurrentDate += "0" + Day +"日";
            }
            return CurrentDate+" "+weeks[date]+" ";
        };

        app.config.globalProperties.$formateDateTime =(day)=>{
            let Year = 0;
            let Month = 0;
            let Day = 0;
            let Hour = 0;
            let Minute = 0;
            let CurrentDate = "";
            Year = day.getFullYear();//ie火狐下都可以
            Month = day.getMonth()+1;
            Day = day.getDate();
            Hour = day.getHours();
            Minute = day.getMinutes();
            CurrentDate += Year + "-";
            if (Month >= 10 )
            {
                CurrentDate += Month + "-";
            }
            else
            {
                CurrentDate += "0" + Month + "-";
            }
            if (Day >= 10 )
            {
                CurrentDate += Day+" ";
            }
            else
            {
                CurrentDate += "0" + Day+" ";
            }
            if (Hour >= 10 )
            {
                CurrentDate += Hour+":";
            }
            else
            {
                CurrentDate += "0" + Hour+":";
            }
            if (Minute >= 10 )
            {
                CurrentDate += Minute ;
            }
            else
            {
                CurrentDate += "0" + Minute ;
            }
            return CurrentDate;
        };

        app.config.globalProperties.$caculatePageNum=(total,pageSize,page)=>{
            let current = 1;
            let dCount = total - 1;
            if(page==1){
                current = 1;
            }else{
                let mod = dCount%pageSize;
                let cpage = dCount/pageSize;
                if(mod==0){
                    if(cpage==0){
                        cpage = cpage +1;
                    }
                }else{
                    cpage = cpage + 1;
                }
                current = cpage;
            }
            return current;
        }
    }
}