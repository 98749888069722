import Axios from 'axios'
import Qs from 'qs'

const axios = Axios.create({
    baseURL: process.env.VUE_APP_API, // 开发地址
    timeout: 60*1000, // 证据上传时文件太大容易超时
    headers: {

    }
})
//axios.defaults.withCredentials = true // 是否携带请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
// 请求拦截器
axios.interceptors.request.use(config => {
    if (config.method === 'post') {
        if(config.processData==undefined){
            config.data.ACTION_EXCLUDE = '';
            let bakData = config.data;
            config.data = Qs.stringify(config.data) || bakData
        }
    }
    return config
}, error => {
    console.log('网络异常，请稍后！');
    return Promise.reject(error)
})

// 响应拦截器
axios.interceptors.response.use(res => {
    return res
}, error => {
    console.log('网络异常，请稍后！');
    return error;
})

export default {
    install: (app, options) => {
        app.config.globalProperties.$post = (url,params,config) => {
            return axios.post(url, params, config)
        }
    }
}